/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import Human, { FaceResult, GestureResult } from "@vladmandic/human"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import * as indexDb from "../indexdb"
import { Config } from "../Config"
import Loading from "../components/Loading"
import { useTranslation } from "react-i18next"
import Header from "./Header"
import { useFaceVerificationCheck } from "./FaceVerifyHooks"
import packageJson from './../../package.json'

const humanConfig = {
  cacheSensitivity: 0,
  modelBasePath: "https://vladmandic.github.io/human-models/models",
  filter: { enabled: true, equalization: true },
  debug: true,
  face: {
    enabled: true,
    detector: {
      rotation: true,
      return: true,
      mask: false,
      maxDetected: 10,
      minConfidence: 0.4,
    },
    description: { enabled: true },
    iris: { enabled: true },
    emotion: { enabled: true },
    antispoof: { enabled: true },
    liveness: { enabled: true },
  },
  body: { enabled: false },
  hand: { enabled: false },
  object: { enabled: false },
  gesture: { enabled: true },
}

const ok: Record<string, { status: boolean | undefined; val: number }> = {
  faceCount: { status: false, val: 1 },
  faceConfidence: { status: false, val: 0 },
  facingCenter: { status: false, val: 0 },
  facingLeft: { status: false, val: 0 },
  facingRight: { status: false, val: 0 },
  headUp: { status: false, val: 0 },
  headDown: { status: false, val: 0 },
  blinkDetected: { status: false, val: 0 },
  // mouthOpen: { status: false, val: 0 },
  faceSize: { status: false, val: 0 },
  antispoofCheck: { status: false, val: 0 },
  livenessCheck: { status: false, val: 0 },
  distance: { status: false, val: 0 },
  age: { status: false, val: 0 },
  gender: { status: false, val: 0 },
  timeout: { status: true, val: 0 },
  descriptor: { status: false, val: 0 },
  elapsedMs: { status: undefined, val: 0 },
  detectFPS: { status: undefined, val: 0 },
  drawFPS: { status: undefined, val: 0 },
}

/**
 * Checks if all the conditions are met for face verification.
 *
 * @return {boolean} Returns true if all the conditions are met, otherwise false.
 */

const allOk = () =>
  ok.faceCount.status &&
  ok.faceSize.status &&
  ok.blinkDetected.status &&
  // ok.mouthOpen.status &&
  ok.facingCenter.status &&
  ok.faceConfidence.status &&
  ok.antispoofCheck.status &&
  ok.livenessCheck.status &&
  ok.distance.status &&
  ok.descriptor.status &&
  ok.age.status &&
  ok.gender.status

const current: { face: FaceResult | null; record: indexDb.FaceRecord | null } =
  { face: null, record: null }

const blink = {
  start: 0,
  end: 0,
  time: 0,
}

const mouth = {
  start: 0,
  end: 0,
  time: 0,
}

enum VerificationStatus {
  NOT_STARTED,
  IN_VERIFICATION,
  VERIFIED,
  FAILED,
}

enum FaceVerificationTypes {
  LEFT,
  RIGHT,
  UP,
  DOWN,
}

enum FaceVerificationResult {
  NOT_STARTED,
  IN_PROGRESS,
  VERIFIED,
  FAILED,
}

const human = new Human(humanConfig)

/**
 * Randomly rearranges the elements of the given FaceVerificationTypes array.
 *
 * @param {FaceVerificationTypes[]} array - the array to be shuffled
 * @return {FaceVerificationTypes[]} the shuffled array
 */

const shuffleArray = (
  array: FaceVerificationTypes[]
): FaceVerificationTypes[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

/**
 * FaceVerify function is a React component that handles face verification process.
 * It initializes the face verification process by calling the init function.
 * It also handles the web camera setup, face detection, and face verification steps.
 * The component renders a UI with a video feed, progress bar, and status indicators.
 * It also displays the face verification status for each face direction (left, right, up, down).
 * The component updates the state variables based on the face verification process.
 * It also triggers the startRecording function when all the conditions are met.
 * The component stops the recording when the face verification process is completed.
 * 
 * @return {JSX.Element} The rendered face verification component.
 */

function FaceVerify() {
  const query = new URLSearchParams(useLocation().search)
  const apiUrl = Config.API_URL
  const cUserId = query.get("cUserId")
  const u = query.get("cUserId")
  const cLanguage = query.get("cLanguage")
  const add = query.get("cIPAddress")
  const { isFaceVerificationCompleted: FaceVerifyResultHooks } =
    useFaceVerificationCheck()

  const timestamp = { detect: 0, draw: 0 }
  let startTime = 0
  let mediaRecorder: MediaRecorder
  let parts: Blob[] = []

  const videoRef = useRef<any>()
  const bgVideoRef = useRef<any>()
  const okRef = useRef<any>()

  const tick: string = require("../Asset/icons/tick.svg").default;
  const cross: string = require("../Asset/icons/cross.svg").default;
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [webCamError, setWebCamError] = useState<boolean>(false)
  const [recording, setRecording] = useState(false)
  const [isRecorded, setIsRecorded] = useState(false)
  const [countdown, setCountdown] = useState(16)
  const { t, i18n } = useTranslation()
  const [, setRecordedVideoData] = useState<Blob[]>([])
  const [isFailed, setIsFailed] = useState(false)

  const [isVerificationInProgress, setIsVerificationInProgress] =
    useState(false)
  const [faceDetectionWarning, setFaceDetectionWarning] = useState<
    string | null
  >(null)
  const [, setIsWarningFixed] = useState(false)
  const [isVerificationCompleted, setIsVerificationCompleted] = useState(false)
  const [noFaceDetected, setNoFaceDetected] = useState(false)
  const [, setMultipleFaceDetected] = useState(false)

  const countdownPercentage = Math.floor(((16 - countdown) / 16) * 100)
  const [isAllConditionsOk, setIsAllConditionsOk] = useState<boolean>(false)

  const [isFaceVerificationCompleted, setIsFaceVerificationCompleted] =
    useState<FaceVerificationResult>(FaceVerificationResult.NOT_STARTED)

  const [faceDirectionInstructions, setFaceDirectionInstructions] = useState<
    FaceVerificationTypes[]
  >([])
  const [, setHasStartedRecording] = useState(false)
  const [currentFaceVerification, setCurrentFaceVerification] = useState<
    FaceVerificationTypes | undefined
  >()
  const [verificationStatus, setVerificationStatus] =
    useState<FaceVerificationResult>(FaceVerificationResult.NOT_STARTED)

  const [faceLeftVerification, setFaceLeftVerification] =
    useState<VerificationStatus>(VerificationStatus.NOT_STARTED)
  const [faceRightVerification, setFaceRightVerification] =
    useState<VerificationStatus>(VerificationStatus.NOT_STARTED)
  const [faceUpVerification, setFaceUpVerification] =
    useState<VerificationStatus>(VerificationStatus.NOT_STARTED)
  const [faceDownVerification, setFaceDownVerification] =
    useState<VerificationStatus>(VerificationStatus.NOT_STARTED)

  useEffect(() => {
    if (cLanguage) {
      i18n.changeLanguage(cLanguage)
    }
  }, [cLanguage])

  useEffect(() => {
    setFaceDirectionInstructions(
      shuffleArray([
        FaceVerificationTypes.UP,
        FaceVerificationTypes.LEFT,
        FaceVerificationTypes.RIGHT,
        FaceVerificationTypes.DOWN,
      ])
    )
  }, [])

  useEffect(() => {
    if (
      isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
      faceLeftVerification !== VerificationStatus.VERIFIED &&
      currentFaceVerification === FaceVerificationTypes.LEFT &&
      ok.facingLeft.status
    ) {
      setFaceLeftVerification(VerificationStatus.VERIFIED)
    }
  }, [ok.facingLeft.status])

  useEffect(() => {
    if (
      isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
      faceRightVerification !== VerificationStatus.VERIFIED &&
      currentFaceVerification === FaceVerificationTypes.RIGHT &&
      ok.facingRight.status
    ) {
      setFaceRightVerification(VerificationStatus.VERIFIED)
    }
  }, [ok.facingRight.status])

  useEffect(() => {
    if (
      isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
      faceUpVerification !== VerificationStatus.VERIFIED &&
      currentFaceVerification === FaceVerificationTypes.UP &&
      ok.headUp.status
    ) {
      setFaceUpVerification(VerificationStatus.VERIFIED)
    }
  }, [ok.headUp.status])
  useEffect(() => {
    if (
      isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
      faceDownVerification !== VerificationStatus.VERIFIED &&
      currentFaceVerification === FaceVerificationTypes.DOWN &&
      ok.headDown.status === true
    ) {
      setFaceDownVerification(VerificationStatus.VERIFIED)
    }
  }, [ok.headDown.status])

  useEffect(() => {
    if (
      faceLeftVerification === VerificationStatus.VERIFIED &&
      faceRightVerification === VerificationStatus.VERIFIED &&
      faceUpVerification === VerificationStatus.VERIFIED &&
      faceDownVerification === VerificationStatus.VERIFIED
    ) {
      setIsFaceVerificationCompleted(FaceVerificationResult.VERIFIED)
    }
  }, [
    faceLeftVerification,
    faceRightVerification,
    faceUpVerification,
    faceDownVerification,
  ])

  useEffect(() => {
    //logs for testing purposes
    console.log("apiUrl", apiUrl)
    console.log("cUserId", u)
    init()
  }, [])

  useEffect(() => {
    if (isAllConditionsOk && !recording && !isRecorded) {
      startRecording()
    }
  }, [isAllConditionsOk, recording, isRecorded])

  useEffect(() => {
    if (countdown > 0 && recording) {
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    }
  }, [countdown, recording])

  useEffect(() => {
    if (isFaceVerificationCompleted === FaceVerificationResult.FAILED) {
      setIsFailed(true)
    }
  }, [isFaceVerificationCompleted])

  async function webCam() {
    try {
      // @ts-ignore resizeMode is not yet defined in tslib
      const cameraOptions: MediaStreamConstraints = { audio: false, video: { facingMode: "user", resizeMode: "cover" } }
      const stream: MediaStream = await navigator.mediaDevices.getUserMedia(
        cameraOptions
      )
      const ready = new Promise((resolve) => {
        videoRef.current.onloadeddata = () => resolve(true)
      })
      videoRef.current.srcObject = stream
      bgVideoRef.current.srcObject = stream
      await ready
      void videoRef.current.play()
      void bgVideoRef.current.play()
      setWebCamError(false)
      return true
    } catch (err) {
      setWebCamError(true)
      return false
    }
  }

  const matchOptions = { order: 2, multiplier: 25, min: 0.2, max: 0.8 }

  const options = {
    minConfidence: 0.4,
    minSize: 224,
    maxTime: 30000,
    blinkMin: 5,
    blinkMax: 800,
    // mouthOpenMin: 100,
    // mouthOpenMax: 2000,
    threshold: 0.5,
    distanceMin: 0.3,
    distanceMax: 1.0,
    mask: humanConfig.face.detector.mask,
    rotation: humanConfig.face.detector.rotation,
    ...matchOptions,
  }

  async function detectionLoop() {
    if (videoRef.current && !videoRef.current.paused) {
      if (current.face?.tensor) human.tf.dispose(current.face.tensor)
      await human.detect(videoRef.current)
      const now = human.now()
      ok.detectFPS.val = Math.round(10000 / (now - timestamp.detect)) / 10
      timestamp.detect = now
      requestAnimationFrame(detectionLoop)
    }
  }

  function drawValidationTests(face: FaceResult) {
    let y = 32
    const isValid = allOk()
    setIsAllConditionsOk(isValid ?? false)
    current.face = face
    for (const [key, val] of Object.entries(ok)) {
      let el = document.getElementById(`ok-${key}`)
      if (!el) {
        el = document.createElement("div")
        el.id = `ok-${key}`
        el.innerText = key
        el.className = "ok"
        el.style.top = `${y}px`
        okRef.current.appendChild(el)
      }
      if (typeof val.status === "boolean")
        el.style.backgroundColor = val.status ? "lightgreen" : "lightcoral"
      const status = val.status ? "ok" : "fail"
      el.innerText = `${key}: ${val.val === 0 ? status : val.val}`
      y += 28
    }
  }

  const setVerificationType = (verificationType: FaceVerificationTypes) => {
    setCurrentFaceVerification(verificationType)
    if (verificationType === FaceVerificationTypes.LEFT) {
      setFaceLeftVerification(VerificationStatus.IN_VERIFICATION)
    } else if (verificationType === FaceVerificationTypes.RIGHT) {
      setFaceRightVerification(VerificationStatus.IN_VERIFICATION)
    } else if (verificationType === FaceVerificationTypes.UP) {
      setFaceUpVerification(VerificationStatus.IN_VERIFICATION)
    } else if (verificationType === FaceVerificationTypes.DOWN) {
      setFaceDownVerification(VerificationStatus.IN_VERIFICATION)
    }
  }

  useEffect(()=>{
    if(isVerificationCompleted){
      downloadRecording(parts)
    }

  },[isVerificationCompleted])

  function startRecording() {
    if (isVerificationInProgress || isVerificationCompleted) {
      return
    }

    setIsVerificationInProgress(true)

    if (videoRef) {
      mediaRecorder = new MediaRecorder(videoRef.current.srcObject)
      if (mediaRecorder) {
        mediaRecorder.start()
        mediaRecorder.ondataavailable = (e) => {
          parts.push(e.data)
        }
        mediaRecorder.onstop = () => {
          // downloadRecording(parts)  
        }
      }

      setCountdown(16)
      setRecording(true)
      setIsRecorded(true)
      setHasStartedRecording(true)
      setIsFaceVerificationCompleted(FaceVerificationResult.IN_PROGRESS)

      setTimeout(() => {
        setVerificationType(faceDirectionInstructions[0])
      })

      setTimeout(() => {
        setVerificationType(faceDirectionInstructions[1])
      }, 4000)

      setTimeout(() => {
        setVerificationType(faceDirectionInstructions[2])
      }, 8000)

      setTimeout(() => {
        setVerificationType(faceDirectionInstructions[3])
      }, 12000)

      setTimeout(() => {
        setIsFaceVerificationCompleted((isFaceVerificationCompleted) => {
          if (isFaceVerificationCompleted === FaceVerificationResult.VERIFIED) {
            setIsVerificationCompleted(true)
            return FaceVerificationResult.VERIFIED
          }
          return FaceVerificationResult.FAILED  
        })
      }, 16000)

      setTimeout(() => {
        setIsVerificationInProgress(false)
        setVerificationType(faceDirectionInstructions[0])
      })

      setTimeout(() => {
        stopRecording(mediaRecorder)
      }, 16000)
    }
  }

  const stopRecording = (mediaRecorder: MediaRecorder) => {
    if (mediaRecorder) {
      setRecording(false)
      setCountdown(0)
      mediaRecorder.stop()
      parts = []
    }
  }

  const downloadRecording = async (parts: Blob[]) => {
    if (current.face?.annotations && cUserId) {
      const blob = new Blob(parts, { type: "video/mp4" })

      const formData = new FormData()
      const video = new File([blob], "video.mp4", { type: "video/mp4" })
      

      formData.append("annotations", JSON.stringify(current.face?.annotations))
      formData.append("cUserId", cUserId)
      formData.append("video", video)

      const requestOptions = {
        method: "POST",
        body: formData,
      }

      fetch(`${apiUrl}/api/verification/face`, requestOptions)
        .then((response) => {
          console.log("Response received", response) // Log that the response is received
          return response.json()
        })
        .then((data) => {
          setRecording(false)
          if (data.status === 200) {
            window.location.href = data.redirectURL + "s/profile"
          } else {
            alert("Something went wrong. Please try again.")
          }
          setRecording(false)
        })
        .catch((error) => {
          console.log(error)
          setRecording(false)
        })
    }
    setRecordedVideoData(parts)
  }

  const decryptedData = () => {
    if (u && add) {
      const encryptedUserId = encodeURIComponent(u)
      const encryptedIpAddress = encodeURIComponent(add)

      fetch(
        `${apiUrl}/api/decrypt/${encryptedUserId}/${encryptedIpAddress}`
      ).then((response) => {
        console.log("Response status:", response)
        return response.text()
      })
    }
  }

  async function validationLoop(): Promise<void> {
    if (isVerificationCompleted) {
      return
    }
    const now = human.now()
    ok.drawFPS.val = Math.round(10000 / (now - timestamp.draw)) / 10
    timestamp.draw = now
    ok.faceCount.val = human.result.face.length
    ok.faceCount.status = ok.faceCount.val === 1
    if (ok.faceCount.val === 0 || ok.faceCount.val > 1) {
      if (ok.faceCount.val === 0) {
        setNoFaceDetected(true)
        setMultipleFaceDetected(false)
      } else {
        setNoFaceDetected(false)
        setMultipleFaceDetected(true)
      }

      setFaceDetectionWarning(
        ok.faceCount.val === 0 ? "noFaceDetectedVerificationFailed" : "multipleFacesDetected"
      )
      setIsWarningFixed(true)
      setVerificationStatus(FaceVerificationResult.FAILED)
      return
    }

    if (ok.faceCount.status) {
      const gestures: string[] = Object.values(human.result.gesture).map(
        (gesture: GestureResult) => gesture.gesture
      )
      if (
        gestures.includes("blink left eye") ||
        gestures.includes("blink right eye")
      )
        blink.start = human.now()
      if (
        blink.start > 0 &&
        !gestures.includes("blink left eye") &&
        !gestures.includes("blink right eye")
      )
        blink.end = human.now()
      ok.blinkDetected.status =
        ok.blinkDetected.status ||
        (Math.abs(blink.end - blink.start) > options.blinkMin &&
          Math.abs(blink.end - blink.start) < options.blinkMax)
      if (ok.blinkDetected.status && blink.time === 0)
        blink.time = Math.trunc(blink.end - blink.start)

      // const mouthPercentageFromGestures = extractMouthPercentage(gestures)
      // if (
      //   mouthPercentageFromGestures !== null &&
      //   mouthPercentageFromGestures >= 60 &&
      //   mouthPercentageFromGestures <= 90
      // ) {
      //   ok.mouthOpen.status = true
      // }

      ok.facingCenter.status = gestures.includes("facing center")
      ok.facingLeft.status = gestures.includes("facing left")
      ok.facingRight.status = gestures.includes("facing right")
      ok.headUp.status = gestures.includes("head up")
      ok.headDown.status = gestures.includes("head down")
      ok.faceConfidence.val =
        human.result.face[0].faceScore || human.result.face[0].boxScore || 0
      ok.faceConfidence.status = ok.faceConfidence.val >= options.minConfidence
      ok.antispoofCheck.val = human.result.face[0].real || 0
      ok.antispoofCheck.status = ok.antispoofCheck.val >= options.minConfidence
      ok.livenessCheck.val = human.result.face[0].live || 0
      ok.livenessCheck.status = ok.livenessCheck.val >= options.minConfidence
      ok.faceSize.val = Math.min(
        human.result.face[0].box[2],
        human.result.face[0].box[3]
      )
      ok.faceSize.status = ok.faceSize.val >= options.minSize
      ok.distance.val = human.result.face[0].distance || 0
      ok.distance.status =
        ok.distance.val >= options.distanceMin &&
        ok.distance.val <= options.distanceMax
      ok.descriptor.val = human.result.face[0].embedding?.length || 0
      ok.descriptor.status = ok.descriptor.val > 0
      ok.age.val = human.result.face[0].age || 0
      ok.age.status = ok.age.val > 0
      ok.gender.val = human.result.face[0].genderScore || 0
      ok.gender.status = ok.gender.val >= options.minConfidence
    }

    ok.timeout.status = ok.elapsedMs.val <= options.maxTime
    drawValidationTests(human.result.face[0])

    if (!allOk() || ok.timeout.status) {
      setTimeout(async () => {
        await validationLoop()
      }, 30)
    }
    ok.elapsedMs.val = Math.trunc(human.now() - startTime)
  }

  function extractMouthPercentage(gestures: string[]): number | null {
    const mouthPercentageRegex = /mouth (\d+)% open/
    for (const gesture of gestures) {
      const match = gesture.match(mouthPercentageRegex)
      if (match) {
        return parseInt(match[1])
      }
    }
    return null
  }

  async function detectFace() {
    if (!current?.face?.tensor || !current?.face?.embedding) return false
    if ((await indexDb.count()) === 0) {
      return false
    }
    const db = await indexDb.load()
    const descriptors = db
      .map((rec) => rec.descriptor)
      .filter((desc) => desc.length > 0)
    const res = human.match.find(
      current.face.embedding,
      descriptors,
      matchOptions
    )
    current.record = db[res.index] || null
    return res.similarity > options.threshold
  }

  async function main() {
    ok.faceCount.status = false
    ok.faceConfidence.status = false
    ok.facingCenter.status = false
    ok.facingLeft.status = false
    ok.facingRight.status = false
    ok.headUp.status = false
    ok.headDown.status = false
    ok.blinkDetected.status = false
    // ok.mouthOpen.status = false
    ok.faceSize.status = false
    ok.antispoofCheck.status = false
    ok.livenessCheck.status = false
    ok.age.status = false
    ok.gender.status = false
    ok.elapsedMs.val = 0
    setIsAllConditionsOk(false)
    await webCam()
    await detectionLoop()
    startTime = human.now()
    validationLoop()

    return detectFace()
  }

  async function init() {
    const webCamResult = await webCam()

    if (!webCamResult) {
      return
    }
    setIsLoading(true)
    await human.load()
    await human.warmup()
    setIsLoading(false)
    await main()
  }

  if (isLoading) {
    return (
      <div className="h-screen w-screen flex flex-row justify-center items-center gap-x-3 relative overflow-hidden">
        <Loading />
      </div>
    )
  }

  if (webCamError) {
    return (
      <div className="h-screen w-screen flex flex-col justify-center items-center gap-y-3 relative overflow-hidden">
        <Header />
        <div className="justify-between text-center m-4">
          {t("pleaseAllowCameraPermissionForVerifyingYourFace")}...
        </div>
      </div>
    )
  }

  if (FaceVerifyResultHooks) {
    return (
      <div className="h-screen w-screen flex flex-col justify-center items-center gap-y-3 relative overflow-hidden">
        <Header />
        <div className="justify-between text-center m-4">
          {t("faceVerificationAlreadyCompletedForThisUser")}...
        </div>
      </div>
    )
  }

  if (
    FaceVerifyResultHooks ||
    verificationStatus === FaceVerificationResult.FAILED
  ) {
    return (
      <div className="h-screen w-screen flex flex-col justify-center items-center gap-y-3 relative overflow-hidden">
        <Header />
        {faceDetectionWarning && <div className="text-xl">{t(faceDetectionWarning)}</div>}
        {verificationStatus === FaceVerificationResult.FAILED && (
          <div className="justify-between text-center m-4">
            {noFaceDetected
              ? t("lookAtCameraStraightAndTurnSlowly")
              : t("pleaseEnsureThatOnlyOnePersonIsInTheCameraFrameAndTryAgain")}
            .
          </div>
        )}
        <button
          className=" bg-yellow-500 mt-2 text-black rounded w-24 h-10"
          onClick={() => {
            decryptedData()
            window.location.reload()
          }}
        >
          {t("retry")}
        </button>
        {/* <div className="flex flex-row items-center gap-x-3">
          <div className="animate-ping w-3 h-3 rounded-full bg-red-600"></div>
          <div className="justify-between m-4">{t('pleaseTurnSlowlyAndSlightly')}</div>
        </div> */}
      </div>
    )
  }

  return (
    <div className="h-screen w-screen flex flex-col relative overflow-hidden bg-white">
      <div className="h-[10%] w-full">
        <Header />
      </div>

      <div className="h-[10%] w-full flex flex-col justify-center items-center">
        {isFaceVerificationCompleted === FaceVerificationResult.NOT_STARTED && (
          <div className="text-center lg:text-lg md:text-md xs:text-xs font-bold">
            <div className="text-black mb-2 lg:mb-2">
              {t("pleaseAlignYourFaceInsideTheCircle")}
            </div>
            <div className="flex flex-col items-center gap-y-2">
              <div className="text-base font-semibold text-red-500 flex items-center">
                <div className="animate-ping w-3 h-3 mr-2 rounded-full bg-red-600"></div>
                {t("pleaseBlinkBothYourEyesToStart")}
              </div>
            </div>
          </div>
        )}
        {isFaceVerificationCompleted === FaceVerificationResult.FAILED && (
          <div className="flex flex-col justify-center items-center text-red-500 text-lg font-bold">
            {t("faceVerificationFailed")}
            <button
              className="bg-yellow-500 mt-2 text-black rounded w-24 h-10"
              onClick={() => {
                window.location.reload()
              }}
            >
              {t("retry")}
            </button>
          </div>
        )}
        {isFaceVerificationCompleted === FaceVerificationResult.VERIFIED && (
          <div className="flex justify-center text-green-400 text-lg font-bold">
            {t("faceVerificationCompleted")}
          </div>
        )}
        {isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS && (
          <div className="flex flex-col items-center text-black text-lg font-bold">
            <div className="text-center text-red-500 mb-2">
              {t("pleaseFollowTheArrows")}
            </div>
            {currentFaceVerification !== undefined && (
              <div className="text-center">
                {currentFaceVerification === FaceVerificationTypes.LEFT && (
                  <> {t("pleaseTurnSlowlyTowardsYourRight")}</>
                )}
                {currentFaceVerification === FaceVerificationTypes.RIGHT && (
                  <> {t("pleaseTurnSlowlyTowardsYourLeft")}</>
                )}
                {currentFaceVerification === FaceVerificationTypes.UP && (
                  <> {t("pleaseLookUpSlowly")}</>
                )}
                {currentFaceVerification === FaceVerificationTypes.DOWN && (
                  <> {t("pleaseLookDownSlowly")}</>
                )}
              </div>
            )}
            {currentFaceVerification === undefined && (
              <div className="text-center">Face verification</div>
            )}
          </div>
        )}
      </div>

      <div className="h-[70%] w-full flex justify-center items-center relative">
        <div className="w-[30%] h-full flex justify-center items-center">
          {isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
            currentFaceVerification === FaceVerificationTypes.RIGHT &&
            faceRightVerification !== VerificationStatus.VERIFIED && (
              <div id="arrowAnim" className="arrow-container">
                {[...Array(3)].map((_, index) => (
                  <div key={index} className={`arrowSliding delay${index}`}>
                    <div className="arrow"></div>
                  </div>
                ))}
              </div>
            )}

          <div className="relative w-full h-full">
            {/* Left Box */}
            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center justify-center sm:hidden">
              {faceRightVerification === VerificationStatus.VERIFIED ? (
                <img
                  src={tick}
                  alt="Tick"
                  className="size-7 lg:size-12 md:size-10 sm:size-10 xs:size-8"
                />
              ) : (
                faceRightVerification === VerificationStatus.IN_VERIFICATION &&
                !ok.facingLeft.status && (
                  <img
                    src={cross}
                    alt="Cross"
                    className="size-7 lg:size-12 md:size-10 sm:size-8 xs:size-6"
                  />
                )
              )}
            </div>
          </div>

          {/* Up Arrow */}
          {isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
            currentFaceVerification === FaceVerificationTypes.UP &&
            faceUpVerification !== VerificationStatus.VERIFIED && (
              <>
                <div
                  id="arrowAnimUp"
                  className="absolute right-4 lg:right-14 md:right-12 sm:right-5 xs:right-2"
                >
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className={`arrowSlidingUp delay${index}`}>
                      <div className="arrowUp"></div>
                    </div>
                  ))}
                </div>
                <div
                  id="arrowAnimUp"
                  className="absolute left-4 lg:left-14 md:left-12 sm:left-5 xs:left-2"
                >
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className={`arrowSlidingUp delay${index}`}>
                      <div className="arrowUp"></div>
                    </div>
                  ))}
                </div>
              </>
            )}

          {/* Down Arrow */}
          {isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
            currentFaceVerification === FaceVerificationTypes.DOWN &&
            faceDownVerification !== VerificationStatus.VERIFIED && (
              <>
                <div
                  id="arrowAnimDown"
                  className="absolute right-4 lg:right-14 md:right-12 sm:right-5 xs:right-2"
                >
                  {[...Array(3)].map((_, index) => (
                    <div
                      key={index}
                      className={`arrowSlidingDown delay${index}`}
                    >
                      <div className="arrowDown"></div>
                    </div>
                  ))}
                </div>
                <div
                  id="arrowAnimDown"
                  className="absolute left-4 lg:left-14 md:left-12 sm:left-5 xs:left-2"
                >
                  {[...Array(3)].map((_, index) => (
                    <div
                      key={index}
                      className={`arrowSlidingDown delay${index}`}
                    >
                      <div className="arrowDown"></div>
                    </div>
                  ))}
                </div>
              </>
            )}
        </div>

        <div className="w-[40%] h-full flex justify-center items-center relative">
          <div className="w-5/7 h-5/7 flex justify-center items-center relative">
            <video
              ref={videoRef}
              muted
              autoPlay
              disablePictureInPicture
              className={`z-10 webCam rounded-full object-cover border-4 p-2 ${
                isFaceVerificationCompleted === FaceVerificationResult.VERIFIED
                  ? "border-green-400"
                  : isFaceVerificationCompleted ===
                    FaceVerificationResult.NOT_STARTED
                  ? "border-transparent"
                  : isFaceVerificationCompleted ===
                      FaceVerificationResult.FAILED ||
                    (currentFaceVerification === FaceVerificationTypes.LEFT &&
                      !ok.facingLeft.status) ||
                    (currentFaceVerification === FaceVerificationTypes.RIGHT &&
                      !ok.facingRight.status) ||
                    (currentFaceVerification === FaceVerificationTypes.UP &&
                      !ok.headUp.status) ||
                    (currentFaceVerification === FaceVerificationTypes.DOWN &&
                      !ok.headDown.status)
                  ? "border-red-500"
                  : "border-green-400"
              }`}
            />

            <video
              ref={bgVideoRef}
              muted
              autoPlay
              className={
                "absolute hidden object-cover bg-center bg-no-repeat bg-cover justify-center bg-red-400"
              }
            />
            <div
              ref={okRef}
              id="ok"
              className="text-xs absolute bottom-5 right-5 z-40 hidden"
            ></div>

            {/* Loader Dots */}
            <div className="absolute inset-0 flex justify-center items-center">
              {Array.from({ length: 35 }, (_, index) => (
                <div
                  key={index}
                  className={
                    isFaceVerificationCompleted ===
                      FaceVerificationResult.VERIFIED ||
                    isFaceVerificationCompleted ===
                      FaceVerificationResult.FAILED
                      ? ""
                      : "loader-dot"
                  }
                  style={{
                    animationDelay: `${index * 0.2}s`,
                    transform: `scale(${index / 70})`,
                  }}
                />
              ))}

              {/* Oval Shape with Plus Sign */}
              {/* <div
                className="absolute z-20 flex justify-center items-center"
                style={{
                  width: "45%",
                  height: "60%",
                  borderRadius: "50% / 70%",
                  border: "2px dotted white",
                  padding: "2px",
                }}
              >
                <div className="text-white text-4xl font-thin"> + </div>
              </div> */}

              {/* Yellow Grid Lines */}
              <div className="absolute inset-0 z-30 flex justify-center items-center">
                <div className="absolute h-[86%] w-px bg-yellow-200 left-1/3 opacity-50"></div>
                <div className="absolute h-[86%] w-px bg-yellow-200 left-2/3 opacity-50"></div>

                <div className="absolute w-[86%] h-px bg-yellow-200 top-1/3 opacity-50"></div>
                <div className="absolute w-[86%] h-px bg-yellow-200 top-2/3 opacity-50"></div>
              </div>
            </div>
          </div>

          {/* Top box */}
          <div className="absolute top-0 w-full h-1/7 flex justify-center items-center lg:top-0 md:top-0 sm:top-10 xs:top-32">
            {faceUpVerification === VerificationStatus.VERIFIED ? (
              <img
                src={tick}
                alt="Tick"
                className="size-7 lg:size-12 md:size-10 sm:size-10 xs:size-8"
              />
            ) : (
              faceUpVerification === VerificationStatus.IN_VERIFICATION &&
              !ok.headUp.status && (
                <img
                  src={cross}
                  alt="Cross"
                  className="size-7 lg:size-11 md:size-9 sm:size-8 xs:size-6"
                />
              )
            )}
          </div>

          {/* Bottom box */}
          <div className="absolute bottom-0 w-full h-1/7 flex justify-center items-center lg:bottom-0 md:bottom-0 sm:bottom-10 xs:bottom-32">
            {faceDownVerification === VerificationStatus.VERIFIED ? (
              <img
                src={tick}
                alt="Tick"
                className="size-7 lg:size-12 md:size-10 sm:size-10 xs:size-8"
              />
            ) : (
              faceDownVerification === VerificationStatus.IN_VERIFICATION &&
              !ok.headDown.status && (
                <img
                  src={cross}
                  alt="Cross"
                  className="size-7 lg:size-11 md:size-9 sm:size-8 xs:size-6"
                />
              )
            )}
          </div>

          {/* Left box */}
          <div className="absolute right-0 w-1/7 h-3/5 justify-center items-center hidden sm:flex">
            {faceLeftVerification === VerificationStatus.VERIFIED ? (
              <img
                src={tick}
                alt="Tick"
                className="size-7 lg:size-12 md:size-10 sm:size-10 xs:size-8"
              />
            ) : (
              faceLeftVerification === VerificationStatus.IN_VERIFICATION &&
              !ok.facingLeft.status && (
                <img
                  src={cross}
                  alt="Cross"
                  className="size-7 lg:size-11 md:size-9 sm:size-8 xs:size-6"
                />
              )
            )}
          </div>

          {/* Right box */}
          <div className="absolute left-0 w-1/7 h-3/5 justify-center items-center hidden sm:flex">
            {faceRightVerification === VerificationStatus.VERIFIED ? (
              <img
                src={tick}
                alt="Tick"
                className="size-7 lg:size-12 md:size-10 sm:size-10 xs:size-8"
              />
            ) : (
              faceRightVerification === VerificationStatus.IN_VERIFICATION &&
              !ok.facingRight.status && (
                <img
                  src={cross}
                  alt="Cross"
                  className="size-7 lg:size-11 md:size-9 sm:size-8 xs:size-6"
                />
              )
            )}
          </div>
        </div>

        <div className="w-[30%] h-full flex justify-center items-center">
          {isFaceVerificationCompleted === FaceVerificationResult.IN_PROGRESS &&
            currentFaceVerification === FaceVerificationTypes.LEFT &&
            faceLeftVerification !== VerificationStatus.VERIFIED && (
              <div id="arrowAnimright" className="arrow-container">
                {[...Array(3)].map((_, index) => (
                  <div
                    key={index}
                    className={`arrowSlidingright delay${index}`}
                  >
                    <div className="arrowright"></div>
                  </div>
                ))}
              </div>
            )}

          <div className="relative w-full h-full">
            {/* Right box */}
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 flex items-center justify-center sm:hidden">
              {faceLeftVerification === VerificationStatus.VERIFIED ? (
                <img
                  src={tick}
                  alt="Tick"
                  className="size-7 lg:size-12 md:size-10 sm:size-10 xs:size-8"
                />
              ) : (
                faceRightVerification === VerificationStatus.IN_VERIFICATION &&
                !ok.facingRight.status && (
                  <img
                    src={cross}
                    alt="Cross"
                    className="size-7 lg:size-12 md:size-10 sm:size-8 xs:size-6"
                  />
                )
              )}
            </div>
          </div>
          <VerificationStatusDisplay />
        </div>
      </div>

      {recording && countdown > 0 && (
        <div className="m-1 p-2 mt-4 flex flex-row items-center justify-center gap-x-2">
          <span className="animate-ping inline-flex h-2 w-2 rounded-full bg-red-400 opacity-75"></span>
          <span className="font-semibold rounded bg-black/50 text-black text-sm md:text-base">
            00:{countdown < 10 ? `0${countdown}` : countdown}
          </span>
        </div>
      )}

      <div className="h-[10%] w-full flex justify-center items-center">
        <div className="flex justify-center items-center w-full">
          <div className="flex w-1/2 h-3 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-400">
            <div
              className="flex flex-col justify-center overflow-hidden text-xs text-white text-center transition-all duration-500 bg-amber-500"
              style={{ width: `${countdownPercentage}%` }}
              role="progressbar"
              aria-valuenow={countdownPercentage}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {Math.max(0, countdownPercentage)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  function VerificationStatusDisplay() {
    return (
      <div className="absolute bottom-5 right-5 z-40">
        <div className="text-sm underline pb-1 font-semibold">
          {t("faceVerificationStatus")}
        </div>

        <VerificationStatusForEach
          type={t("left")}
          faceVerification={faceRightVerification}
        />
        <VerificationStatusForEach
          type={t("right")}
          faceVerification={faceLeftVerification}
        />
        <VerificationStatusForEach
          type={t("up")}
          faceVerification={faceUpVerification}
        />
        <VerificationStatusForEach
          type={t("down")}
          faceVerification={faceDownVerification}
        />
      </div>
    )
  }

  function VerificationStatusForEach({
    type,
    faceVerification,
  }: {
    type: string
    faceVerification: VerificationStatus
  }) {
    const [isFailed, setIsFailed] = useState(false)

    useEffect(() => {
      if (
        isFaceVerificationCompleted === FaceVerificationResult.FAILED &&
        VerificationStatus[faceVerification] !== "VERIFIED"
      ) {
        setIsFailed(true)
      }
    }, [isFaceVerificationCompleted])

    return (
      <div
        className={`text-xs ${!isFailed ? "text-green-500" : "text-red-500"}`}
      >
        {type}:{" "}
        {isFailed ? t("Failed") : t(VerificationStatus[faceVerification])}
      </div>
    )
  }
}

export default FaceVerify
